import dayjs from "dayjs";
import { Link } from "react-router-dom";

export const columns = (fns:{deleteTeam:Function,loading:boolean})=>{

 const COLUMNS = [
  {
    Header: "PEAK",
    accessor: "peakName",
  },
  {
    Header: "TEAM NAME",
    accessor: "team",
  },
  {
    Header: "CAPTAIN NAME",
    accessor: "captainName",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "DATE CREATED",
    Cell: (tableInfo: any) => {
      return dayjs(new Date(tableInfo.data[tableInfo.row.index].creationTime)).format("YYYY-MM-DD");
    },
  },
  {
    Header: "TIME CREATED",
    Cell: (TableInfo: any) => {
      return dayjs(new Date(TableInfo.data[TableInfo.row.index].creationTime)).format("HH:mm");
    },
  },
  {
    Header: "STATUS",
    accessor: "teamStatus",
    Cell: ({ value }: any) => {
      let status = "";

      switch (value) {
        case 1:
          status = "Pending";
          break;
        case 2:
          status = "Approved";
          break;
        case 3:
          status = "Declined";
          break;
        case "4":
        case "5":
          status = "Pending";
      }

      return <p>{status}</p>;
    },
  },
  {
    Header: "ACTIONS",
    width:"20%",
    Cell: (tableInfo: any) => {
      const peakToken = tableInfo.data[tableInfo.row.index].peakToken;
      const teamName = tableInfo.data[tableInfo.row.index].teamName;

      return (
        <div style={{display:"flex"}}>
        <Link
          to={`/submission/${peakToken}/${teamName}`}
          className={+tableInfo.data[tableInfo.row.index].teamStatus === 1 ? "btn orange" : "btn"}>
          {+tableInfo.data[tableInfo.row.index].teamStatus === 1 ? "Review" : "View/Edit"}
        </Link>
        {fns.loading ?
          <button
            className= "btn outline" style={{marginLeft:"20px"}} disabled>
            Deleting
          </button>:
          <button
            className= "btn outline" style={{marginLeft:"20px"}} onClick={()=>fns.deleteTeam(teamName,peakToken)}>
            Delete
          </button>}
          </div>
      );
    },
  },
];

return COLUMNS

}