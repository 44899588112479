import { Route, Navigate } from "react-router-dom";
import { Winners, NoMatch, Participants, Participant } from "../Pages";
import { Layout } from "../Components";
import Submissions from "../Pages/Submissions";
import SubmissionDetails from "../Pages/Submissions/SubmissionDetails";
import Challenges from "../Pages/Challenges";
import Challenge from "../Pages/Challenges/Challenge";
import Leaderboard from "../Pages/Leaderboard";
import MediaReplace from "../Pages/MediaReplace";
import Peaks from "../Pages/Peaks";
import AddEditPeak from "../Pages/Peaks/addEditpeak";

export default (
  <>
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="/peaks" />} />
      <Route path="peaks" element={<Peaks />} />
      <Route path="peaks/:type" element={<AddEditPeak />} />
      <Route path="peaks/:type/:token" element={<AddEditPeak />} />
      <Route path="submissions" element={<Submissions />} />
      <Route path="submission" element={<SubmissionDetails />} />
      <Route path="submission/:peakToken?/:teamName?" element={<SubmissionDetails />} />
      <Route path="Challenges" element={<Challenges />} />
      <Route path="Challenge" element={<Challenge />} />
      <Route path="/challenge/:id?" element={<Challenge />} />
      <Route path="leaderboard" element={<Leaderboard />} />
      <Route path="media/:peakToken/:teamName" element={<MediaReplace />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  </>
);
