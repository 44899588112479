import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { publicCampaignClient } from '../../api';

import './dropzone.css';
import dayjs from 'dayjs';

type Props = {
    file_name: string
    language?: string
    set_video?: Function
    handleVideoUrl?: Function
    peakToken?:string
    teamName?:string
    teamVideo?:boolean
}


function DropZone(props: Props) {

    const [fileUploader, setFileUploader] = useState(null);

    const [percentage, setPercentage] = useState(0);

    const [errors, setErrors] = useState('');

    const [fileUp, setFileUp] = useState(null)

    let _file = null

    const dragOver = (e:any) => {
        e.preventDefault();
    }

    const dragEnter = (e:any) => {
    }

    const dragLeave = (e:any) => {
        e.preventDefault();
    }

    const fileDrop = async (e:any) => {
        e.preventDefault();

        const accept_file = e.dataTransfer.files[0];

        const video_type = accept_file.type.match(/^video*/);

        if (!video_type){
            setErrors('Sorry, Please upload a video.');
            return;
        }


        const resp = await publicCampaignClient.call<{uploadUrl:string, key: string,downloadUrl:string}>('getFileUploadUrl', {
            peakToken: props.peakToken,
            teamName: props.teamName,
            contentType: accept_file.type,
            filename: accept_file.name
        })

        if (resp.uploadUrl){
            axios
              .put(resp.uploadUrl, accept_file, {
                onUploadProgress: function (progressEvent:any) {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );

                  setPercentage(percentCompleted);

                },
                headers: {
                  "Content-Type": accept_file.type,
                },
              })
              .then((res) => {

                if (props.handleVideoUrl){

                    props.handleVideoUrl(resp.downloadUrl);
                }

                if (props.set_video){

                    if (props.language === 'en'){
                        props.set_video('cag_video_url', resp.downloadUrl)
                    }else{
                        props.set_video('cag_video_url_fr', resp.downloadUrl)
                    }

                }

               // props.setFile({ preview: urltodisplay[0] });
              })
              .catch((e) => {
                //setUploadError(t("Upload failed"));
              });
        }


        //const URL = window.URL || window.webkitURL;

        //console.log(URL.createObjectURL(files))

        //fileUploader.addFile(URL.createObjectURL(files))
        //  var reader = new FileReader();
        // reader.onload = function(e) {
        //     fileUploader.addFile(file);
        // };
        // reader.onerror = function(e){
        //    alert('目前只能上传文件');
        // }
        // reader.readAsDataURL(file);
        //     console.log(files)
        //     //fileUploader.init()
        //_uploader.current.addFile(new plupload.File(files))
        //     //console.log(fileUploader)
        //     //fileUploader.start()
        //     setFileUp(new plupload.File(files))
       // _file = files

        //setFileUp(files)

        //fileUploader.init()
        //fileUploader.start()
        // if (files.length) {
        //     handleFiles(files);
        // }
    }

    const handleFiles = (files:any) => {
    }

    let _uploader = useRef(null)

    useEffect(() => {

        if (fileUp) {

            // const _uploader = new plupload.Uploader({
            //     runtimes: 'html5,html4',
            //     browse_button: 'uploader',
            //     container: 'drop-container',
            //     //drop_element: 'drop-container',
            //     url: `${window.host}/mackenzie/portal_updvideo.php`,
            //     max_file_count: 1,
            //     chunk_size: '5mb',
            //     autostart: false,
            //     multipart: true,
            //     multipart_params: {
            //         "media_type": "video",
            //         "team_token": props.file_name,
            //         // "thumbnail_file": thumbnail.toDataURL(),
            //         "name": fileUp.name
            //     },
            //     buttons: {
            //         stop: false
            //     },
            //     filters: {
            //         max_file_size: "100mb",
            //         mime_types: [
            //             { title: "Mp4 files", extensions: "mp4" },
            //             { title: "Mov files", extensions: "mov" },
            //             { title: "mkv files", extensions: "mkv" }
            //         ]
            //     },
            //     init: {
            //         PostInit: function (up) {
            //             //console.log("init before add", up);
            //             //up.addFile(_file)
            //             //up.trigger("AddFiles", _file);
            //             //console.log("init after add", up);
            //             //console.log('file init', fileUp)
            //             //console.log('init');
            //             setErrors('');

            //             //if (fileUp) {
            //             up.addFile(fileUp)
            //             //console.log(up)
            //             window.$.blockUI(BLOCK_CSS);
            //             up.start()
            //             //}

            //             //up.start()
            //         },
            //         FilesAdded: (up, file) => {
            //             // console.log('file added', file)
            //             //up.start()
            //         },
            //         FileUploaded: (up, file, result) => {
            //             //console.log("uploaded");
            //             //console.log(result);
            //             const {response, status} = result;
            //             const resp = JSON.parse(response);
            //             if (resp.status === "ok") {

            //                 if (props.handleVideoUrl){

            //                     props.handleVideoUrl(resp.result);

            //                 }else{

            //                     if (props.language === 'en'){
            //                         //props.set_video(resp.result)
            //                         props.set_video('cag_video_url', resp.result)
            //                         //console.log('setup cag_video en', resp.result)
            //                     }else{
            //                         props.set_video('cag_video_url_fr', resp.result)
            //                         //console.log('setup cag_video fr', resp.result)
            //                     }

            //                 }



            //                 window.$.unblockUI();
            //                 // const formData = new FormData();
            //                 // formData.append("team_token", tk);
            //                 // formData.append("seq", media_idx);
            //                 // formData.append("thumbnail_file", thumbnail.toDataURL());
            //                 // updthumbnail(formData).then(data => {
            //                 //     if (data.status === "ok") {
            //                 //         history.replace(`/addphoto/${pk}/${tk}`);
            //                 //     } else if(data.status === "failed" && data.result === "ENTRY_NOT_EXIST") {
            //                 //         vex.dialog.alert({
            //                 //             unsafeMessage: t("ENTRY_NOT_EXIST")
            //                 //         });
            //                 //     } else if (data.status === "failed" && data.result === "WRONG_DATE") {
            //                 //         vex.dialog.alert({
            //                 //             unsafeMessage: t("ENTRY_NOT_EXIST")
            //                 //         });
            //                 //     }
            //                 // });
            //             } else {
            //                 console.error("upload failed");
            //             }
            //         },
            //         UploadComplete: (up, file) => {
            //             window.$.unblockUI();
            //             console.log("complete");

            //             // todo call another ws to post thumbnail
            //             // history.replace(`/addphoto/${pk}/${tk}`);
            //         },
            //         BeforeUpload: (up, files) => {
            //             //console.log("before upload", up, files);
            //         },
            //         Error: (up, error) => {

            //             if (error.code == '-601'){
            //                 setErrors('Sorry, Please upload a video.');
            //             }
            //             if (error.code == '-600'){
            //                 setErrors('sorry, the maximum file size is 100 MB.');
            //             }else{
            //                 setErrors('Sorry, something seems to have gone wrong. Please try again later.')
            //             }
            //             console.log(error);
            //         }
            //     }
            // });

            // _uploader.init()

            // setFileUploader(_uploader);

        }



        // return function cleanup() {
        //     _uploader.current = null
        //   };


    }, [fileUp])

    return (
        <div className="content">
            <div className="container">
                <div className="drop-container" id="drop-container"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                >
                    <input type="hidden" name="uploader" id="uploader"></input>
                    <div className="drop-message">
                        <div className="upload-icon"></div>
                    Drag &amp; Drop a file here

                    </div>

                </div>
                <div className="row mt-2">
                    <div className="col-12">
                    { percentage ? <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{"width": `${percentage}%`}} aria-valuenow={percentage} aria-valuemin={0} aria-valuemax={100}>{`${percentage}%`}</div>
                    </div> : <></>}
                    </div>

                </div>
            </div>
            <div className="form-group mt-1">
            { errors &&  <div className="error ml-3"><i className="fas fa-exclamation-circle" />{ errors }</div>}
            </div>

        </div>



    )
}

export default DropZone
