import { useEffect } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";

type Props = {
  columns: any;
  data: any;
  filterValue?: string;
  tablePageSize: number;
  loading?: boolean;
  pageCount?: any;
};

export const Table = ({ columns, data, filterValue, tablePageSize }: Props) => {
  const maxPageShow = 6;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: tablePageSize },
      useRowSelect,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // // which has only the rows for the active page

    // // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    setGlobalFilter,
  } = tableInstance;

  const handleDebounceFilter = useAsyncDebounce((value) => setGlobalFilter(value), 100);

  useEffect(() => {
    handleDebounceFilter(filterValue);
  }, [filterValue]);

  useEffect(() => {
    setPageSize(tablePageSize);
  }, [tablePageSize]);

  const caculateShowPage = (pgcnt: any, pgidx: any) => {
    const totalPages = [...Array(pgcnt)].map((_, i) => i);

    if (pgidx + maxPageShow > totalPages.length) {
      return totalPages.slice(maxPageShow * -1);
    } else {
      return totalPages.slice(pgidx, pgidx + maxPageShow);
    }
  };

  return (
    <div className="grid">
      <table className="table" {...getTableProps()}>
        <thead className="thead-light">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => {
                    // Apply the header cell props

                    return (
                      // <th
                      //   scope="col"
                      //   {...column.getHeaderProps({
                      //     style: {
                      //       width: column.width,
                      //     },
                      //   })}
                      // >
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                        <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                      </th>
                    );
                  })
                }
              </tr>
            ))
          }
        </thead>
        {data.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row: any) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr style={{ fontSize: "16px" }} {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell: any) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={8} rowSpan={5} style={{ textAlign: "center", padding: "100px 0" }}>
                No records to display
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {data.length > tablePageSize && (
        <div className="pagination">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    previousPage();
                  }}
                  disabled={!canPreviousPage}>
                  <span aria-hidden="true">&lt;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
              {pageIndex > 0 && pageIndex + maxPageShow <= pageOptions.length ? (
                <>
                  <li className="page-item ">
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        gotoPage(0);
                      }}>
                      1
                    </a>
                  </li>
                  <li className="page-item ">
                    <a className="page-link">...</a>
                  </li>
                </>
              ) : (
                <></>
              )}
              {caculateShowPage(pageCount, pageIndex).map(function (page) {
                return (
                  <li
                    className={page == pageIndex ? "page-item active" : "page-item"}
                    key={page}
                    onClick={(e) => {
                      e.preventDefault();
                      gotoPage(page);
                    }}>
                    <a className="page-link" href="#">
                      {page + 1}
                    </a>
                  </li>
                );
              })}
              {pageIndex < pageCount - maxPageShow ? (
                <>
                  <li className="page-item ">
                    <a className="page-link">...</a>
                  </li>
                  <li className="page-item ">
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        gotoPage(pageCount - 1);
                      }}>
                      {pageCount}
                    </a>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                  }}
                  aria-label="Next"
                  disabled={!canNextPage}>
                  <span aria-hidden="true">&gt;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};
