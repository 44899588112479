import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { campaignClient, publicCampaignClient } from "../../api";
import { PageLoader, SideBar, Table } from "../../Components";
import { columns } from "./Columns";
import { useLocation } from "react-router";
import { Modal } from "react-bootstrap";
import styles from "../Submissions/submission.module.css";
import { ToastContainer, toast, Slide } from "react-toastify";

const notify = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 1000,
    transition: Slide,
  });
};
const notifyError = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
    transition: Slide,
  });
};

const Peaks = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showdelete, setShowDelete] = useState(false);
  const [selectedPeak, setSelectedPeak] = useState("");

  useEffect(() => {
    if (location.state?.message) {
      notify(`${location.state.message}`);
      location.state = {};
    }
    if (location.state?.error) {
      notifyError(`${location.state.error}`);
      location.state = {};
    }
  }, [location.state]);

  const {
    isLoading: isLoadingPeaks,
    data: peaks,
    refetch,
  } = useQuery("getParticipants", async () => {
    const res: { peaks: any[] } = await publicCampaignClient.call("getallpeaks", {});
    return res.peaks;
  });

  const deletePeak = async (peakToken: string) => {
    if (loading) {
      return;
    }
    setShowDelete(false);
    setLoading(true);
    const res = await campaignClient.call<{ result: boolean; error?: string; message?: string }>(
      "deletePeak",
      { peakToken }
    );
    if (res.error) {
      location.state = { error: res.message };
      //   notify(`${res.message}`);
      setLoading(false);
    }
    if (res.result) {
      setLoading(false);
      location.state = { message: "Peak successfully deleted." };
      refetch();
    }
  };
  const functions = {
    setShowDelete,
    loading,
    setSelectedPeak,
  };

  const columns1 = React.useMemo(() => columns(functions), []);

  const data = useMemo(() => {
    if (peaks && peaks.length > 0) {
      let filtered = peaks;
      return filtered.map((peak: any) => {
        return {
          peakName: peak.peakName,
          peakToken: peak.peakToken,
          hashTag: peak.peakCode,
          teams: peak.teams || 0,
          creationTime: new Date(peak.creationTime),
          updateTime: peak.updateTime,
        };
      });
    }
  }, [peaks]);

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <SideBar />

        {isLoadingPeaks || loading ? (
          <PageLoader />
        ) : (
          <div className="page-content submissions">
            <div className="container-fluid">
              {(location.state?.message || location.state?.error) && (
                <div className="row">{<ToastContainer />}</div>
              )}
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <div className="left">
                    <p>Show</p>
                    <select
                      className="form-control"
                      name="page_size"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <p>submissions</p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-8">
                  <div className="right">
                    <div className="flex" style={{ display: "flex" }}>
                      <div className="col-sm-12 col-md-8">
                        <form>
                          <input
                            type="text"
                            className="form-control"
                            name="filter"
                            aria-describedby="Search Filter"
                            placeholder="Search"
                            value={filter}
                            onChange={(e) => {
                              setFilter(e.target.value);
                            }}
                          />
                        </form>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <Link
                          className="btn"
                          style={{
                            padding: "7px 10px 7px 10px",
                            minWidth: "30px",
                            fontSize: "14px",
                          }}
                          to="/peaks/add">
                          Add peak
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row-reverse mt-3">
                <div className="col-2">
                  {/* <a className="btn orange" onClick={ e=>handleExport(e) }style={{ padding: "5px"}} href="#">Export</a>  */}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="tabs">
                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab">
                        <Table
                          columns={columns1}
                          data={data ? data : []}
                          loading={isLoadingPeaks}
                          filterValue={filter}
                          tablePageSize={pageSize}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal show={showdelete} onHide={() => setShowDelete(false)}>
        <Modal.Header style={{ border: "none", textAlign: "center" }}>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowDelete(false)}>
            <span aria-hidden="true">
              <img src="/assets/images/ico-close-black.png" alt="close" />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body style={{ border: "none", textAlign: "center", padding: "20px 65px 10px 65px" }}>
          <div>
            <h2>Delete Peak?</h2>
            <br />
            <p>
              Are you sure you would like to delete this peak? any teams that are part of this peak
              will be deleted as well.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <div className="actionBtns">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <button
                  type="button"
                  className="btn orange"
                  onClick={() => deletePeak(selectedPeak)}>
                  Delete
                </button>
              </div>

              <div className="col-sm-12 col-md-6">
                <button type="button" className="btn outline" onClick={() => setShowDelete(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Peaks;
