import { useEffect, useState } from "react";
import { Outlet, useNavigate, useMatch } from "react-router-dom";
import MenuFooter from "./MenuFooter";
import SideBar from "./SideBar";
import SideBarHeader from "./SideBarHeader";
import { useStores } from "../../stores";

function Layout() {
  const match = useMatch({ path: "participant/:key", end: true });

  const { authStore } = useStores();

  const [toggle, setToggle] = useState(false);

  const [mobileToggle, setMobileToggle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClick = (event: any) => {
      setToggle(false);
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  const logOut = async () => {
    try {
      await authStore.logout();

      navigate("/", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return <> 
      <Outlet />
</>
}

export default Layout;
