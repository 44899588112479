import { Spinner } from "react-bootstrap";

function PageLoader() {
  return <div style={{ width: '100%', textAlign: 'center', padding: '200px 0'}}>
      <Spinner animation="border" role="status">
  <span className="visually-hidden"></span>
</Spinner>

  </div>;
}

export default PageLoader;
