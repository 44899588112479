import React from 'react'

import { ToastContainer, toast, Slide } from 'react-toastify'
//import 'react-toastify/dist/ReactToastify.css'
import { campaignClient } from '../../api';

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const notify = (points=0, error=false) => {

  const options  ={
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 800,
    transition: Slide,
    onClose: () => window.location.reload()
  }

  if (error) {
    
    toast.error('something seems to have gone wrong. Please try again.', options)
  }else{
    
    toast.success(`${points} Points Successfully Added.`, options);
  }
  

}

export const COLUMNS = [
    {
        Header: 'POSITION',        
        Cell: (tableInfo:any)=>(
            <span>{ (tableInfo.row.index + 1) }</span>
        )
      },
    {
        Header: 'PEAK',
        accessor: 'peakName', 
      },
  {
    Header: 'POINTS',
    accessor: 'points', 
  },
  {
    Header: 'ADD POINTS',
        Cell: (tableInfo:any)=>(                
        <input className="form-control addpoints"  name="point" type="number" min="0" />
    )
  },          
  {
    Header: 'ACTIONS',             
    Cell: (tableInfo:any, updateMyData:any)=>(    
      <><a href="#" data-toggle="modal" data-target="#addpoints" className="btn orange" 
      onClick={
        (e:any)=>{
          e.preventDefault()
          const ele_point = e.target.parentNode.previousElementSibling.firstChild
          // console.log(e)
          // console.log(e.target.parentNode.previousElementSibling.firstChild)
          // console.log(e.target.parentNode.previousElementSibling.firstChild.value)
          const points = ele_point.value

          if (points <= 0){
            window.vex.dialog.alert({
              unsafeMessage: 'Please enter correct number.'
          })
          return
          }

          window.vex.dialog.buttons.YES.text = 'Confirm'
        window.vex.dialog.buttons.NO.text = 'Cancel'
        window.vex.dialog.confirm({
          unsafeMessage: "<h1><strong>Add Points</strong></h1><p>Are you sure you want to add points to this team?Once added, points cannot be removed.</p>",
            // buttons: [{               
            //     text: 'YES',
            //     type: 'submit',
            //     className: 'vex-dialog-button-primary vex-dialog-button vex-first', value: '1', data: '2'
            // }, {text: 'NO', type: 'button', className:'vex-dialog-button-secondary vex-dialog-button vex-last'}],
            callback: function(value:any){
                //console.log(data.points)
                if (value){

                  campaignClient.call('addpeakpoints', {peakToken: tableInfo.data[tableInfo.row.index].peakToken, points}).then((res:any)=>{

                    if (res && res.peakToken){                       
                     window.location.reload()
                      ele_point.value = ''
                      
                    }else{
                      notify(points, true)
                    }

                  }).catch(e=>{  window.vex.dialog.alert({
                    unsafeMessage: 'Error, Please try again later.'
                })})
                  
                }else{
                    return
                }
               }
        })
        return;
        
        
        }
      }>Add Points</a>
      <ToastContainer />
      </>
    )
  },
]


