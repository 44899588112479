import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "cropperjs/dist/cropper.css";
import { useNavigate, Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { SideBar, NavBack, DropZone, PageLoader } from "../../Components";
import Cropper from "react-cropper";
// import Modal from "react-modal";
import { ToastContainer, toast, Slide } from "react-toastify";
import { campaignClient, publicCampaignClient } from "../../api";
import { b64toBlob, resizeImage } from "../../helpers/utils";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { Team } from "../Submissions/SubmissionDetails";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
const notify = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 912000,
    transition: Slide,
  });
};
const BLOCK_CSS = {
  css: {
    border: "none",
    padding: "15px",
    backgroundColor: "#000",
    "-webkit-border-radius": "10px",
    "-moz-border-radius": "10px",
    opacity: 0.5,
    color: "#fff",
  },
  message: "",
};

function MediaReplace() {
  const params = useParams();
  const peakToken = params.peakToken;
  const teamName = params.teamName!.replace(/_/g, " ");

  //const seq = props.match.params.seq;

  const [image, setImage] = useState<any>("");
  const [cropper, setCropper] = useState<any>("");
  const [sumiting, setSubmiting] = useState(false);
  const [fileName, setFileName] = useState("");

  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [player, setPlayer] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);

  const [preview, setPreview] = useState(null);


  const [errors, setErrors] = useState<any>("");

  const d = new Date();

  //const temp_file_name = MD5(param.toString() + d.toLocaleTimeString()).toString();

  const cropperRef = useRef<HTMLImageElement>(null);

  const navigate = useNavigate();

  const { isLoading: isLoadingTeam, data: team } = useQuery("teammedia", async () => {
    const res: { teams: Team[] } = await campaignClient.call("teams", {
      peakToken: params.peakToken,
      teamName,
    });
    return res.teams[0];
  });

  useEffect(() => {
    if (team && team.thumbnailKey) {
      setImage(team?.thumbnailKey);
    }
    if (team && team.videoKey) {
      setVideoUrl(team?.videoKey);
    }
  }, [team]);

  function openModal() {
    setIsOpen(true);
  }

  const onChange = (e: any) => {

    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        setImage(reader.result?.toString() || "");
      });
      setFileName("f"+dayjs().format("YYYYMMDDHHmm") + e.target.files[0].name);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onStart = (e: any) => {
    if (!fileName) {
      const image_url = team?.thumbnailKey;
      const filename = image_url!.substring(image_url!.lastIndexOf("/") + 1);
      setFileName(filename.split(".")[0] + dayjs().format("YYYMMDDHHmm"));
    }
  };

  const handleConfirm = async () => {
    if (typeof cropper !== "undefined" && fileName) {
      if (!cropper.getCroppedCanvas()) {
        window.vex.dialog.alert({ unsafeMessage: "Please select a photo file." });
        return;
      }

      setSubmiting(true);

      const croppedCanvas = cropper.getCroppedCanvas();

      // const ratio = {
      //                       w: 490 / croppedCanvas.width,
      //                       h: 400 / croppedCanvas.height
      //                    };
      // const _temp = resizeImage(ratio, croppedCanvas, true);

      const thumbnail_file: any = croppedCanvas.toDataURL();

      const block = thumbnail_file.split(";");
      const contentType = block[0].split(":")[1];

      const realData = block[1].split(",")[1];
      const blob = b64toBlob(realData, contentType);

      const resp: { uploadUrl: string; key: string; downloadUrl: string } =
        await publicCampaignClient.call<{
          uploadUrl: string;
          key: string;
          downloadUrl: string;
        }>("getFileUploadUrl", {
          peakToken,
          teamName,
          contentType,
          filename: fileName,
        });

      let thumbnailKey = "";

      if (resp.uploadUrl) {
        axios
          .put(resp.uploadUrl, blob, {
            onUploadProgress: function (progressEvent: any) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
            headers: {
              "Content-Type": contentType,
            },
          })
          .then(async (res) => {
            thumbnailKey = resp.downloadUrl;

            campaignClient
              .call("updmedia", {
                peakToken: params.peakToken,
                teamName: params.teamName,
                thumbnailKey,
                videoKey: videoUrl,
              })
              .then((res: any) => {
                if (res && res.error) {
                  window.vex.dialog.alert({ unsafeMessage: res.message });
                  return;
                }

                if (res && res.teams && res.teams.length > 0) {
                  navigate(`/submission/${params.peakToken}/${params.teamName}`, { replace: true });
                }

                setSubmiting(false);
              });
          })
          .catch((e) => {
            setSubmiting(false);
            window.vex.dialog.alert({ unsafeMessage: "failed" });
          });
      }

      return;
    }

    if (!fileName) {
      campaignClient
        .call("updmedia", {
          peakToken: params.peakToken,
          teamName: params.teamName,
          thumbnailKey: team!.thumbnailKey,
          videoKey: videoUrl,
        })
        .then((res: any) => {
          if (res && res.error) {
            window.vex.dialog.alert({ unsafeMessage: res.message });
            return;
          }

          if (res && res.teams && res.teams.length > 0) {
            navigate(`/submission/${params.peakToken}/${params.teamName}`, { replace: true });
          }
          setSubmiting(false);
        });
    }
  };

  return (
    <div className="page-wrapper chiller-theme toggled">
      <div className="page-content reviewsubmission">
        <SideBar />
        {loading || isLoadingTeam ? (
          <PageLoader />
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <h2>Add/Update Profile Photo/Video</h2>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="mt-4 mb-2">Photo File Select</label>
                      <input
                        type="file"
                        className="form-control-file"
                        accept="image/*"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Cropper
                      src={image}
                      style={{
                        height: "auto",
                        width: "100%",
                        maxWidth: "640px",
                      }}
                      //initialAspectRatio={490 / 440}
                      initialAspectRatio={490 / 440}
                      zoomTo={0.5}
                      guides={false}
                      // viewMode={1}
                      minCropBoxHeight={490}
                      minCropBoxWidth={440}
                      background={false}
                      responsive={true}
                      zoomable={false}
                      zoomOnWheel={false}
                      autoCropArea={1}
                      checkOrientation={false}
                      checkCrossOrigin={true}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      ref={cropperRef}
                      cropstart={onStart}
                      // crop={onCrop}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-6">
                    {team?.videoKey && (
                      <>
                        <div className="form-group">
                          <label>Video Preview</label>
                        </div>
                        <ReactPlayer controls={true} url={videoUrl} style={{ width: "100%" }} />
                      </>
                    )}
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-6">
                    <label htmlFor="exampleInputPassword1">Add/Update a Video</label>
                    <br />
                    <small className="pb-5">
                      Drop a video file only if you want to add/update a video.
                    </small>
                    <br className="clear" />

                    <DropZone
                      file_name={""}
                      teamVideo={true}
                      teamName={params.teamName}
                      peakToken={params.peakToken}
                      handleVideoUrl={(url: string) => {
                        url && setVideoUrl(url);
                      }}
                    />
                    {errors && errors.video_url && (
                      <div className="error-message">
                        <i className="fas fa-exclamation-circle" />
                        {errors.video_url}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="files">
                      <p className="name">{videoUrl ? videoUrl.split("/").pop() : ""}</p>
                      <p className="delete">
                        {videoUrl ? (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setVideoUrl("");
                            }}>
                            Delete
                          </a>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-2">
                    {sumiting ? (
                      <Button variant="secondary" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden"> Confirm</span>
                      </Button>
                    ) : (
                      <button
                        className="btn orange mr-5"
                        onClick={() => {
                          handleConfirm();
                        }}>
                        Confirm
                      </button>
                    )}
                  </div>
                  <div className="col-2">
                    <Link
                      to={`/submission/${params.peakToken}/${params.teamName}`}
                      className="btn outline ml-2">
                      Back
                    </Link>
                  </div>
                </div>

                <ToastContainer />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MediaReplace;
