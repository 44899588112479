import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { SideBar, Table, NavBack, DropZone } from "../../Components";
import { campaignClient, publicCampaignClient } from "../../api";
import ReactPlayer from "react-player";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

type ChallengForm = {
  cag_seq: number;
  cag_title: string;
  cag_title_fr: string;
  cag_desc: string;
  cag_desc_fr: string;
  cag_video_url: string;
  cag_video_url_fr: string;
  challengeKey:string;
};

const notify = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 912000,
    transition: Slide,
  });
};

export default function Challenge() {
  const [challenge, setChallenge] = useState<any>(null);

  const params = useParams();

  const navigate = useNavigate();

  const defaultValues = {
    challengeKey: challenge ? challenge.challengeKey : "",
    cag_seq: challenge ? challenge.cag_seq : "",
    cag_title: challenge ? challenge.cag_title : "",
    cag_title_fr: challenge ? challenge.cag_title_fr : "",
    cag_desc: challenge ? (challenge.cag_desc ? challenge.cag_desc : "") : "",
    cag_desc_fr: challenge ? (challenge.cag_desc_fr ? challenge.cag_desc_fr : "") : "",
    cag_video_url: challenge ? (challenge.cag_video_url ? challenge.cag_video_url : "") : "",
    cag_video_url_fr: challenge
      ? challenge.cag_video_url_fr
        ? challenge.cag_video_url_fr
        : ""
      : "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    getValues,
    watch,
    reset,
  } = useForm<ChallengForm>({ mode: "onChange", defaultValues: { ...defaultValues } });

  const formwatch = watch();

  useEffect(() => {
    campaignClient.call("challenges", {}).then((res: any) => {
      if (res && res.challenges && res.challenges.length > 0) {
        const matched = res.challenges.filter((v: any) => (v.challengeKey = params.id))[0];

        if (matched) {
          reset({
            challengeKey:matched.challengeKey,
            cag_title: matched.title_en,
            cag_title_fr: matched.title_fr,
            cag_desc: matched.desc_en,
            cag_desc_fr: matched.desc_fr,
            cag_video_url: matched.video_en,
            cag_video_url_fr: matched.video_fr,
            cag_seq: matched.seq,
          });
        }
      }
    });
  }, [params]);

  const handleSubmita = async (values: any, isSubmitting: any) => {
    const post_data = {
      challengeKey: values.challengeKey,
      title_en: values.cag_title,
      title_fr: values.cag_title_fr,
      desc_en: values.cag_desc,
      desc_fr: values.cag_desc_fr,
      video_en: values.cag_video_url,
      video_fr: values.cag_video_url_fr,
      seq: values.cag_seq,
    };

    await campaignClient.call("challenge", post_data).then((res: any) => {
      if (res && res.challengeKey) {
        notify("Challenge updated.");
        setTimeout(() => {
          navigate("/challenges", { replace: true });
        }, 1000);
        return;
      }

      if (!res || !res.challengeKey) {
        notify("Challenge updated failed.");
        return;
      }
    });

    return;
  };

  return (
    <div className="page-wrapper chiller-theme toggled">
      <SideBar />

      <div className="page-content reviewsubmission">
        <div className="container-fluid">
          <NavBack url={"challenges"} />
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="content">
                <h2>Edit challenge</h2>
                <div className="formContent">
                  <form onSubmit={handleSubmit(handleSubmita)}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Week #</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("cag_seq", {
                          required: { value: true, message: "Please enter week number." },
                        })}
                        min="1"
                        aria-describedby="Challenge Title"
                      />
                      {errors.cag_seq && (
                        <div className="error-message">
                          <i className="fas fa-exclamation-circle" />
                          {errors.cag_seq.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Challenge - EN</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("cag_title", {
                            required: { value: true, message: "Please enter Challenge title." },
                          })}
                        aria-describedby="Challenge Title"
                      />
                      {errors.cag_title && (
                        <div className="error-message">
                          <i className="fas fa-exclamation-circle" />
                          {errors.cag_title.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Challenge -FR</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cag_title_fr"
                        {...register("cag_title_fr", {
                            required: { value: true, message: "Please enter Challenge title in french." },
                          })}                        aria-describedby="Challenge Title"
                      />
                      {errors.cag_title_fr && (
                        <div className="error-message">
                          <i className="fas fa-exclamation-circle" />
                          {errors.cag_title_fr.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Description - EN</label>
                      <input
                        type="textarea"
                        className="form-control"
                        id="cag_desc"
                        {...register("cag_desc", {
                            required: { value: true, message: "Please enter Challenge description." },
                          })}  
                      />
                      {errors.cag_desc && (
                        <div className="error-message">
                          <i className="fas fa-exclamation-circle" />
                          {errors.cag_desc.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Description - FR</label>
                      <input
                        type="textarea"
                        className="form-control"
                        id="cag_desc_fr"
                        {...register("cag_desc_fr", {
                            required: { value: true, message: "Please enter Challenge description in french." },
                          })} 
                      />
                      {errors.cag_desc_fr && (
                        <div className="error-message">
                          <i className="fas fa-exclamation-circle" />
                          {errors.cag_desc_fr.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Add a Video - EN</label>
                      <br />
                      {formwatch.cag_video_url ?  <ReactPlayer
                          controls={true}
                          url={formwatch.cag_video_url}
                          style={{ width: "100%" }}
                        /> :
                      <DropZone
                        file_name={""}
                        set_video={setValue}
                        language={"en"}
                        teamVideo={false}
                        peakToken="weeklyChallenges"
                        teamName={`${dayjs().format("YYYYMMDDHHmm")}`}
                      />}
                      {errors.cag_video_url && (
                        <div className="error-message">
                          <i className="fas fa-exclamation-circle" />
                          {errors.cag_video_url.message}
                        </div>
                      )}
                    </div>
                    <div className="files">
                      <p className="name">
                        {formwatch.cag_video_url ? formwatch.cag_video_url.split("/").pop() : ""}
                      </p>
                      <p className="delete">
                        {formwatch.cag_video_url ? (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setValue("cag_video_url", "");
                            }}>
                            Delete
                          </a>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Add a Video - FR</label>
                      <br />
                      {formwatch.cag_video_url_fr ?  <ReactPlayer
                          controls={true}
                          url={formwatch.cag_video_url_fr}
                          style={{ width: "100%" }}
                        /> :

                      <DropZone file_name={""} set_video={setValue} language={"fr"} teamVideo={false}
                        peakToken="weeklyChallenges"
                        teamName={`${dayjs().format("YYYYMMDDHHmm")}`} />
                      }
                      {errors.cag_video_url_fr && (
                        <div className="error-message">
                          <i className="fas fa-exclamation-circle" />
                          {errors.cag_video_url_fr.message}
                        </div>
                      )}
                    </div>
                    <div className="files">
                      <p className="name">
                        {formwatch.cag_video_url_fr
                          ? formwatch.cag_video_url_fr.split("/").pop()
                          : ""}
                      </p>
                      <p className="delete">
                        {formwatch.cag_video_url_fr ? (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setValue("cag_video_url_fr", "");
                            }}>
                            Delete
                          </a>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    <div className="actionBtns">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <button type="submit" className="btn orange">
                            Update
                          </button>
                        </div>

                        <div className="col-sm-12 col-md-6">
                          <Link to="/challenges">
                            <button type="button" className="btn outline">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>

                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
