import React, { useState, useEffect, useMemo } from "react";
import { SideBar, Table, PageLoader } from "../../Components";
import { columns } from "./Columns";
import { campaignClient } from "../../api";
import { byKey } from "../../helpers/utils";
import { Team } from "./SubmissionDetails";
import { useQuery } from "react-query";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useLocation } from "react-router";

const notify = (message: string) => {
  
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
    transition: Slide,
  });
};
const notifyError = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
    transition: Slide,
  });
};

const Submissions = () => {
  const [tableFilterData, setTableFilterData] = useState<any[]>([]);
  const location = useLocation();

  const [active, setActive] = useState("all");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const {
    isLoading: isLoadingTeams,
    data: teams,
    refetch,
  } = useQuery("teams", async () => {
    const res: { teams: Team[] } = await campaignClient.call("teams", {});
    return res.teams;
  });

  useEffect(() => {
    if (location.state?.message) {
      notify(`${location.state.message}`);
      location.state = {};
    }
    if (location.state?.error) {
      notifyError(`${location.state.error}`);
      location.state = {};
    }
  }, [location.state]);

  useEffect(() => {
    if (teams) {
      handleTabClick("all");
    }
  }, []);

  const status = {
    pending: 1,
    approved: 2,
    declined: 3,
  };

  const handleTabClick = (tab: string) => {
    setActive(tab);
  };

  const data = useMemo(() => {
    if (teams && teams.length > 0) {
      let filteredTeams = teams;

      if (active === "pending") {
        filteredTeams = teams.filter((t) => +t.teamStatus === 1);
      }
      if (active === "approved") {
        filteredTeams = teams.filter((t) => +t.teamStatus === 2);
      }
      if (active === "declined") {
        filteredTeams = teams.filter((t) => +t.teamStatus === 3);
      }

      return filteredTeams.map((team: Team) => {
        return {
          ...team,
        };
      });
    }
  }, [active, teams]);

  const deleteTeam = async (teamName: string, peakToken: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await campaignClient.call<{ result: boolean; error?: string; message?: string }>(
      "deleteTeam",
      { teamName, peakToken }
    );
    if (res.error) {
      location.state = { error: res.message };
      setLoading(false);
    }
    if (res.result) {
      setLoading(false);
      location.state = { message: "Team successfully deleted." };
      refetch();
    }
  };
  const functions = {
    deleteTeam,
    loading,
  };

  const columns1 = React.useMemo(() => columns(functions), []);

  return (
    <div className="page-wrapper chiller-theme toggled">
      <SideBar />

      {isLoadingTeams || loading ? (
        <PageLoader />
      ) : (
        <div className="page-content submissions">
          <div className="container-fluid">
            {<div className="row">{<ToastContainer />}</div>}
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="left">
                  <p>Show</p>
                  <select
                    className="form-control"
                    name="page_size"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <p>submissions</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-8">
                <div className="right">
                  <form>
                    <input
                      type="text"
                      className="form-control"
                      name="filter"
                      aria-describedby="Search Filter"
                      placeholder="Search"
                      value={filter}
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-3">
              <div className="col-2">
                {/* <a className="btn orange" onClick={ e=>handleExport(e) }style={{ padding: "5px"}} href="#">Export</a>  */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="tabs">
                  <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <a
                        className={
                          active === "all" ? "nav-item nav-link active" : "nav-item nav-link"
                        }
                        id="nav-home-tab"
                        href="#all"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                        onClick={(e) => handleTabClick("all")}>
                        All Submissions
                      </a>
                      <a
                        className={
                          active === "approved" ? "nav-item nav-link active" : "nav-item nav-link"
                        }
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#approved"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                        onClick={(e) => handleTabClick("approved")}>
                        Approved
                      </a>
                      <a
                        className={
                          active === "pending" ? "nav-item nav-link active" : "nav-item nav-link"
                        }
                        id="nav-contact-tab"
                        data-toggle="tab"
                        href="#pending"
                        role="tab"
                        aria-controls="nav-contact"
                        aria-selected="false"
                        onClick={(e) => handleTabClick("pending")}>
                        Pending
                      </a>
                      <a
                        className={
                          active === "declined" ? "nav-item nav-link active" : "nav-item nav-link"
                        }
                        id="nav-about-tab"
                        data-toggle="tab"
                        href="#declined"
                        role="tab"
                        aria-controls="nav-about"
                        aria-selected="false"
                        onClick={(e) => handleTabClick("declined")}>
                        Declined
                      </a>
                    </div>
                  </nav>

                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab">
                      <Table
                        columns={columns1}
                        data={data ? data : []}
                        loading={loading}
                        filterValue={filter}
                        tablePageSize={pageSize}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Submissions;
