import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { PageLoader, SideBar } from "../../Components";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { campaignClient, publicCampaignClient } from "../../api";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useQuery } from "react-query";
import { provinces } from "../../helpers/utils";

export type Peak = {
  peakToken?: string;
  peakName: string;
  peakCode: string;
  city?: string;
  province?: string;
  peakUrl?: string;
};
const notify = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
    transition: Slide,
  });
};
const notifyError = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
    transition: Slide,
  });
};

function AddEditPeak() {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const peakToken = params.token || "";

  const { isLoading: isLoadingPeak, data: peak } = useQuery(
    ["getPeak", peakToken],
    async () => {
      const res: { peak: Peak } = await publicCampaignClient.call("getPeak", { peakToken });
      return res.peak;
    },
    { enabled: !!peakToken }
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Peak>({
    mode: "onChange",
    defaultValues: { peakName: "", peakCode: "", city: "", province: "", peakUrl: "" },
  });

  useEffect(() => {
    if(peak){
      reset({ ...peak,province:peak.province?peak?.province.toLowerCase():"" });
    }
  }, [peak,reset]);

  const createPeak = async (data: Peak) => {
    if(loading){return}
    setLoading(true)
    const res = await campaignClient.call<{ result: boolean; error?: string; message?: string }>(
      "addPeak",
      { ...data,type:"new" }
    );
    if (res.error) {
      notify(`${res.message}`);
      setLoading(false);
    }
    if (res.result) {
      setLoading(false);
      navigate("/peaks", { state: { message: "Peak successfully added." } });
    }
  };
  const updatePeak = async (data: Peak) => {
    if(loading){return}
    setLoading(true)
    const res = await campaignClient.call<{ result: boolean; error?: string; message?: string }>(
      "editPeak",
      { ...data }
    );
    if (res.error) {
      notifyError(`${res.message}`);
      setLoading(false);
    }
    if (res.result) {
      setLoading(false);
      navigate("/peaks", { state: { message: "Peak successfully updated." } });
    }
  };

  return (
    <div className="page-wrapper chiller-theme toggled">
      <div className="page-content reviewsubmission">
        <SideBar />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Link to="/peaks" className="topBtn">
                <i className="fas fa-arrow-left"></i>Back to all peaks
              </Link>
            </div>
          </div>

          <ToastContainer />

          {isLoadingPeak || loading? (
            <PageLoader />
          ) : (
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="content">
                  <h2>{params.type === "add" ? "Add a new peak" : "Edit peak"}</h2>
                  <div className="formContent">
                    <form onSubmit={handleSubmit(params.type === "add" ? createPeak : updatePeak)}>
                      <div className="form-group">
                        <label htmlFor="email">* Peak name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("peakName", {
                            required: { value: true, message: "Please enter peak name" },
                          })}
                        />
                        {errors.peakName && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.peakName.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="peakCode">* Hashtag</label>
                        <input
                          type="text"
                          className="form-control"
                          id="peakCode"
                          {...register("peakCode", {
                            required: { value: true, message: "Please enter a hash tag" },
                          })}
                        />
                        {errors.peakCode && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.peakCode.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="peakCode">* City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="peakCode"
                          {...register("city", {
                            required: { value: true, message: "Please enter a city." },
                          })}
                        />
                        {errors.city && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.city.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="peakCode">* Province</label>
                        <select
                          className="form-control"
                          id="province"
                          {...register("province", {
                            required: { value: true, message: "Please select a province" },
                          })}>
                          <option value="">Plese select</option>
                          {Object.keys(provinces).map((p) => (
                            <option value={p.toLowerCase()}>{provinces[p]}</option>
                          ))}
                        </select>
                        {errors.province && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.province.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="peakCode">Peak Url</label>
                        <input
                          type="text"
                          className="form-control"
                          id="peakUrl"
                          {...register("peakUrl", {
                            required: { value: false, message: "Please enter the website url for peak" },
                          })}
                        />
                        {errors.peakUrl && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.peakUrl.message}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <button
                            type="submit"
                            className="btn orange"
                            style={{ padding: "7px 10px 7px 10px" }}
                            disabled={isSubmitting ? true : false}>
                            {params.type === "add" ? "Create peak" : "Update peak"}
                          </button>
                        </div>

                        <div className="col-sm-12 col-md-6">
                          <button
                            type="button"
                            className="btn outline"
                            disabled={isSubmitting ? true : false}
                            style={{ padding: "7px 10px 7px 10px" }}
                            onClick={() => navigate("/peaks")}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div></div>
        </div>
      </div>
    </div>
  );
}

export default AddEditPeak;
