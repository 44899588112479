import dayjs from "dayjs";
import { Link } from "react-router-dom";

export const columns = (fns: {
  setShowDelete: Function;
  loading: boolean;
  setSelectedPeak: Function;
}) => {
  const COLUMNS = [
    {
      Header: 'No',        
      Cell: (tableInfo:any)=>(
          <span>{ (tableInfo.row.index + 1) }</span>
      )
    },
    {
      Header: "PEAK NAME",
      accessor: "peakName",
    },
    {
      Header: "HASHTAG",
      accessor: "hashTag",
    },
    {
      Header: "TEAMS",
      accessor: "teams",
    },

    {
      Header: "EDITED",
      Cell: (tableInfo: any) => {
        return tableInfo.data[tableInfo.row.index].updateTime
          ? dayjs(tableInfo.data[tableInfo.row.index].updateTime).format("YYYY-MM-DD")
          : "";
      },
    },

    {
      Header: "ACTIONS",
      Cell: (tableInfo: any) => {
        const peakToken = tableInfo.data[tableInfo.row.index].peakToken;
        return (
          <>
            <Link
              to={`/peaks/edit/${peakToken}`}
              className={
                +tableInfo.data[tableInfo.row.index].teamStatus === 1 ? "btn orange" : "btn"
              }>
              {+tableInfo.data[tableInfo.row.index].teamStatus === 1 ? "Review" : "View/Edit"}
            </Link>
            {fns.loading ? (
              <button className="btn outline" style={{ marginLeft: "20px" }} disabled>
                Deleting
              </button>
            ) : (
              <button
                className="btn outline"
                style={{ marginLeft: "20px" }}
                onClick={async () => {
                  fns.setSelectedPeak(peakToken);
                  fns.setShowDelete(true);
                }}>
                Delete
              </button>
            )}
          </>
        );
      },
    },
  ];

  return COLUMNS;
};
