import React, { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import {SideBar, Table, PageLoader} from '../../Components';
import { campaignClient, publicCampaignClient } from '../../api';
import { COLUMNS } from './Columns';
import {orderBy} from "lodash"
import { useQuery } from "react-query";


 const Leaderboard = () => {

    const [tableData, setTableData] = useState<any[]>([])
    const [active, setActive] = useState('all')
    const [filter, setFilter] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const navigate = useNavigate()

    const { isLoading: isLoadingLeaderboard, data: leaderboard } = useQuery(
        "adminLeaderboard",
        async () => {
          const res: any = await campaignClient.call("adminLeaderboard", {});
          const sortLeaderboard = orderBy(res.peaks, ['points'], ['desc'])
          return sortLeaderboard
        }
      );



    const columns = COLUMNS

    

    return <div className="page-wrapper chiller-theme toggled">

        <SideBar />

        { isLoadingLeaderboard ? <PageLoader /> : <div className="page-content submissions">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <div className="left">
                            <p>Show</p>
                            <select className="form-control" name="page_size" value={pageSize} onChange={
                                e => {
                                    setPageSize(Number(e.target.value))
                                }
                            }>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                            <p>submissions</p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="right">
                            <form>
                                <input type="text" className="form-control" name="filter" aria-describedby="Search Filter" placeholder="Search" value={filter}
                                    onChange={
                                        e => {
                                            setFilter(e.target.value)
                                        }

                                    }
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="tabs">
                            <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <Table columns={columns} data={leaderboard} loading={isLoadingLeaderboard} filterValue={filter} tablePageSize={pageSize} />


                                </div>
                                {/* <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <p>Approved</p>
                                </div>
                                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <p>Pending</p>
                                </div>
                                <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                                    <p>Declined</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 }
    </div>


    //     <>
    //     <SideBar />
    //     <div className="contentFixed">
    //         <div className="content">
    //             <div className="head">
    //                 <div className="left">
    //                     {/* <h2>Total To Judge: 100</h2> */}
    //                     <h3>Evaluated: { evaluated }</h3>
    //                 </div>
    //                 <div className="right">
    //                     <ul>
    //                         <li className={ active === 'all' ? 'active' : ''}><a href="#" onClick={e=>handleFilterClick(e, 'all')}>All</a></li>
    //                         <li className={ active === 'rated' ? 'active' : ''}><a href="#" onClick={e=>handleFilterClick(e, 'rated')}>RATED</a></li>
    //                         <li className={ active === 'unrated' ? 'active' : ''}><a href="#" onClick={e=>handleFilterClick(e, 'unrated')}>Unrated</a></li>
    //                     </ul>
    //                 </div>
    //             </div>           

    //                 <Table 
    //                 columns={columns}
    //                 data={tableData}               
    //         loading={loading}
    //         pageCount={pageCount}
    //                 />               

    //             </div>


    //     </div>
    // </>
}

export default Leaderboard