import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom"
import CustomLink from "../CustomLink";
import { useStores } from "../../stores";

function SideBar() {

  const location = useLocation();

  const { authStore } = useStores();

  const navigate = useNavigate();

  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();

    setProfile(user.attributes.profile);
  };

  const logOut = async () => {
    try {
      await authStore.logout();

      navigate("/", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return <nav id="sidebar" className="sidebar-wrapper">
  <div className="sidebar-content">
      <div className="sidebar-brand">
          <img src="/assets/images/logo-white.png" className="logoWhite" alt="Logo" />
      </div>


      <div className="sidebar-menu">
          <ul>
          <li className={ location.pathname === '/peaks' ? 'active':''}>
                  <Link to="/peaks">Peaks</Link>
              </li>
              <li className={ location.pathname === '/submissions' ? 'active':''}>
                  <Link to="/submissions">Submissions</Link>
              </li>
              <li className={ location.pathname === '/leaderboard' ? 'active':''}>
                  <Link to="/leaderboard">Leaderboard</Link>
              </li>
              <li className={ location.pathname === '/challenges' ? 'active':''}>
                  <Link to="/challenges">Challenges</Link>
              </li>
              {/* <li className={ location.pathname === '/mackenziecup' ? 'active':''}>
                  <Link to="/mackenziecup">Mackenzie Cup</Link>
              </li> */}
              <a href="#" className="logout" onClick={ e=> { e.preventDefault(); logOut(); }}>Logout</a>
          </ul>
      </div>

  </div>

</nav>
}

export default SideBar;
