import React, { useState, useEffect } from "react"
import {SideBar, Table} from '../../Components';
import { useNavigate, Link } from "react-router-dom"
import { COLUMNS } from './Columns'
import { campaignClient, publicCampaignClient } from "../../api";
import Challenge from './Challenge';

 const Challenges = () => {

    const [tableData, setTableData] = useState<any[]>([])
    const [active, setActive] = useState('all')
    const [evaluated, setEvaluated] = useState(0)
    const [filter, setFilter] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const navigate = useNavigate()




    useEffect(()=>{
        setLoading(true)
        campaignClient.call('challenges', {}).then((res:any)=>{
            if (res && res.challenges){
                setTableData([...res.challenges])
            }
        })
    },[])

    // const handleFilterClick = (e, type) => {
    //     e.preventDefault()
    //     setActive(type)
    //     getSubmission(type).then(({status, result, code})=>{
    //         if (status === 'ok'){
    //             setTableData([...result])
    //             setEvaluated(code)
    //         }
    //     })
    // }

      const columns = React.useMemo(() => COLUMNS, [])

    const [loading, setLoading] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0)

    const handleFilterChange = (fn:any) => {

    }

    return <div className="page-wrapper chiller-theme toggled">
        <SideBar />
        <div className="page-content submissions challenges">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <div className="left">
                            <Link to="/challenge" className="btn orange">Create a New Challenge</Link>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="right">
                            <form>
                                <input type="text" className="form-control" name="filter" aria-describedby="Search Filter" placeholder="Search" value={filter}
                                onChange={
                                    e=>{
                                        setFilter(e.target.value)
                                    }

                                }
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="tabs">
                            <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                      <Table  columns={columns} data={tableData} loading={loading} filterValue={filter} tablePageSize={pageSize} />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


//     <>
//     <SideBar />
//     <div className="contentFixed">
//         <div className="content">
//             <div className="head">
//                 <div className="left">
//                     {/* <h2>Total To Judge: 100</h2> */}
//                     <h3>Evaluated: { evaluated }</h3>
//                 </div>
//                 <div className="right">
//                     <ul>
//                         <li className={ active === 'all' ? 'active' : ''}><a href="#" onClick={e=>handleFilterClick(e, 'all')}>All</a></li>
//                         <li className={ active === 'rated' ? 'active' : ''}><a href="#" onClick={e=>handleFilterClick(e, 'rated')}>RATED</a></li>
//                         <li className={ active === 'unrated' ? 'active' : ''}><a href="#" onClick={e=>handleFilterClick(e, 'unrated')}>Unrated</a></li>
//                     </ul>
//                 </div>
//             </div>

//                 <Table
//                 columns={columns}
//                 data={tableData}
//         loading={loading}
//         pageCount={pageCount}
//                 />

//             </div>


//     </div>
// </>
}

export default Challenges;