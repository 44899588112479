import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { campaignClient } from '../../api';
//import { deleteChallenge } from '../api/api'

//import { ToastContainer, toast, Slide } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

// const notify = (points=0) => toast.success(`${points} Points Successfully Added.`, {
//   position: toast.POSITION.TOP_CENTER,
//   hideProgressBar: true,
//   autoClose: 2000,
//   transition: Slide
// });

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };


export const COLUMNS = [
    {
        Header: 'Name',
        accessor: 'title_en',
      },
    {
        Header: 'DESCRIPTION',
        accessor: 'desc_en',
      },
  {
    Header: 'DATE CREATED',
    sortType: "datetime",
      accessor: "creationTime",
      Cell: (TableInfo: any) => {
        
        return dayjs(new Date(TableInfo.data[TableInfo.row.index].creationTime)).format('YYYY-MM-DD');
      },

  },
  {
    Header: 'TIME CREATED',
    sortType: "datetime",
      Cell: (TableInfo: any) => {
        
        return dayjs(new Date(TableInfo.data[TableInfo.row.index].creationTime)).format('HH:mm');
      },

  },
  {
    Header: 'ACTIONS',
    accessor: 'token',
    Cell: (tableInfo:any)=>(
      <> <Link to={`/challenge/${tableInfo.data[tableInfo.row.index].challengeKey}`} className="btn">
      View/Edit
    </Link>
      <a  data-toggle="modal" data-target="#addpoints" className="btn outline" onClick={
        e=>{
          e.preventDefault()
          window.vex.dialog.buttons.YES.text = 'Confirm'
          window.vex.dialog.buttons.NO.text = 'Cancel'
          window.vex.dialog.confirm({
            unsafeMessage: "<h1><strong>Delete</strong></h1><p>Are you sure you want to delete this challenge?</p>",
              callback: function(value:any){
                  //console.log(data.points)
                  if (value){

                    //console.log('delete', tableInfo.data[tableInfo.row.index].cag_id)
                    campaignClient.call('delchallenge', {challengeKey: tableInfo.data[tableInfo.row.index].challengeKey}).then((res:any)=>{
                      if (res && res.challengeKey){
                        window.location.reload()
                      }
                    })

                  }else{
                      return
                  }
                 }
          })

        }
      }>Delete</a></>
    )
  },
]


