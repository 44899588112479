import { Link } from 'react-router-dom';

type Props =  {
    url?: string
}

export default function NavBack({url}: Props) {
    return (
        <div className="row">
        <div className="col-sm-12 col-md-12">
            <Link to={url ? `/${url}` : '/submissions'} className="topBtn"><i className="fas fa-arrow-left"></i>Back to all { url ? 'challenges' : 'submissions'}</Link>
        </div>
    </div>
    )
}
