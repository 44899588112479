import React, { useState, useEffect, useRef } from "react";
import styles from "./submission.module.css";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { SideBar, NavBack, PageLoader } from "../../Components";
import { campaignClient } from "../../api";
import ReactPlayer from "react-player";
import Modal from "react-modal";

import { ToastContainer, toast, Slide } from "react-toastify";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { birthYearRegex, emailRegex, looseNorthAmericanPhoneRegex } from "../../helpers/utils";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export type Team = {
  team: string;
  captainName: string;
  peakName: string;
  email: string;
  yearOfBirth: string;
  address: string;
  city: string;
  phone: string;
  cause: string;
  cause_other: string;
  question1: string;
  question2: string;
  videoKey: string;
  thumbnailKey: string;
  teamStatus: number;
  declineReason: string;
};

const notify = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 912000,
    transition: Slide,
  });
};

function SubmissionDetails() {
  const params = useParams();

  const location = useLocation();
  const [player, setPlayer] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);

  const [preview, setPreview] = useState(null);

  const [loading, setLoading] = useState(false);

  const { isLoading: isLoadingTeam, data: team } = useQuery(["teamDetails",params.peakToken], async () => {
    const res: { teams: Team[] } = await campaignClient.call("teams", {
      peakToken: params.peakToken,
      teamName: `${location.hash ? `${params.teamName}${location.hash}` : params.teamName}`,
    });
    return res.teams[0];
  },{enabled:!!params.peakToken});

  useEffect(() => {
    reset({ ...team });
  }, [team]);

  const defaultValues = {
    peakName: "",
    teamName: "",
    peak: "",
    team: "",
    captainName: "",
    email: "",
    yearOfBirth: "",
    address: "",
    city: "",
    phone: "",
    cause: "",
    cause_other: "",
    question1: "",
    question2: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    reset,
  } = useForm<Team>({ mode: "onChange", defaultValues: { ...defaultValues } });

  const formwatch = watch();

  const navigate = useNavigate();

 

  function openModal() {
    setIsOpen(true);
  }
  Modal.setAppElement("#root");

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: "1000",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    setPreview(null);
    setPlayer("");
  }

  const handleApprove = () => {
    window.vex.dialog.buttons.YES.text = "Yes";
    window.vex.dialog.buttons.NO.text = "Cancel";
    window.vex.dialog.confirm({
      unsafeMessage: "Are you sure?",
      callback: function (value: any) {
        if (value) {
          SubmitForm({ ...formwatch, teamStatus: 2 }, setLoading);
        } else {
          return;
        }
      },
    });
    return;
  };
  const handleDecline = () => {
    const ele = window.document.getElementById("reason");
    if (ele) {
      setValue("declineReason", ele.innerText);
    }
    setValue("teamStatus", 3);
    SubmitForm({ ...formwatch, teamStatus: 3 }, setLoading);
    closeModal();
    return;
  };

  const handleMediaClick = (e: any) => {
    e.preventDefault();

    const ele = e.target;

    const url = ele.dataset.url;

    if (url) {
      setPlayer(url);
      setPreview(null);
    } else {
      setPlayer("");
      setPreview(null);
    }

    openModal();
  };

  const renderMedias = (video_url: string, img_url: string) => {
    const elements = (
      <div className="col-sm-12 ">
        <div className="record">
          {video_url ? (
            <a href="#" onClick={(e) => {e.preventDefault(); handleMediaClick(e)}}>
              <img
                src={img_url + "?v=" + new Date().getTime()}
                alt="img"
                data-url={video_url}
                className="reviewImg mb-1"
              />
              <img
                src="/assets/images/svg/ic_play.svg"
                alt="decoration"
                className="videoplay_btn"

              />
            </a>
          ) : (
            <img
              src={img_url + "?v=" + new Date().getTime()}
              alt="img"
              data-url={video_url}
              className="reviewImg mb-1"
            />
          )}

          <p className="replaceLink mr-3">
            <Link to={`/media/${params.peakToken}/${params.teamName}`}>Replace file</Link>
          </p>
          {/* { idx >= 3 && <p className="deleteLink"><a href="#" onClick={ e=>{ handleDeleteMedia(e, v) }}>Delete file</a></p>} */}
        </div>
      </div>
    );

    return elements;
  };

  const renderButtons = (entry_status: any, isSubmitting: any) => {
    if (+entry_status === 1 || +entry_status === 5) {
      return (
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <button
              type="button"
              className="btn orange"
              onClick={handleApprove}
              disabled={isSubmitting ? true : false}>
              Approve Submission
            </button>
          </div>

          <div className="col-sm-12 col-md-6">
            <button
              type="button"
              className="btn outline"
              disabled={isSubmitting ? true : false}
              onClick={openModal}>
              Decline Submission
            </button>
          </div>
        </div>
      );
    }

    if (+entry_status === 0 || +entry_status === 2) {
      return (
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <button type="submit" className="btn orange" disabled={isSubmitting ? true : false}>
              Update
            </button>
          </div>

          <div className="col-sm-12 col-md-6">
            <Link to="/submissions">
              <button type="button" className="btn outline">
                Back
              </button>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <button type="submit" className="btn orange" disabled={isSubmitting ? true : false}>
              Update
            </button>
          </div>

          <div className="col-sm-12 col-md-6">
            <Link to="/submissions">
              <button type="button" className="btn outline">
                Back
              </button>
            </Link>
          </div>
        </div>
      );
    }
  };

  const updateForm = async (data: Team) => {
    await campaignClient
      .call("team", { ...data, cause: data.cause === "Other" ? data.cause_other : data.cause })
      .then((res: any) => {
        if (res && res.error) {
          notify(res.message);
          setLoading(false);
          return;
        } else {
          notify("Team submission's successfully updated.");
          setLoading(false);
          return;
        }
      })
      .catch((e) => {
        window.vex.dialog.alert({
          unsafeMessage: "Error, Please try again later.",
        });
      });
  };

  const SubmitForm = (values: any, setSubmitting: any) => {
    let message = "Team submission's successfully updated.";

    if (+team!.teamStatus == 1 && +values.teamStatus === 2) {
      message = `Team submission's successfully approved.`;
    }

    if (+team!.teamStatus == 1 && +values.teamStatus === 3) {
      message = `Team submission's successfully declined.`;
    }

    campaignClient
      .call("team", {
        ...values,
        cause: values.cause === "Other" ? values.cause_other : values.cause,
      })
      .then((res: any) => {
        if (res && res.error) {
          notify(res.message);
          setSubmitting(false);
          return;
        }

        if (res && res.teams && res.teams.length > 0) {
          navigate("/submissions", { state: { message: message } });
          return;
        } else {
          notify(message);
          setSubmitting(false);
        }
        return;
      })
      .catch((e) => {
        window.vex.dialog.alert({
          unsafeMessage: "Error, Please try again later.",
        });
      });
  };

  return (
    <div className="page-wrapper chiller-theme toggled">
      <div className="page-content reviewsubmission">
        <SideBar />
        {isLoadingTeam ? (
          <PageLoader />
        ) : (
          <div className="container-fluid">
            <NavBack />

            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="content">
                  <h2>Team captain contact info</h2>
                  <div className="formContent">
                    <form onSubmit={handleSubmit(+team!.teamStatus < 2 ? updateForm : SubmitForm)}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Peak</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("peakName", {
                            required: { value: true, message: "Please add a peak name" },
                          })}
                          disabled
                        />
                        {errors.peakName && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.peakName?.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="team_name">Team Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="team"
                          aria-describedby="Team Name"
                          {...register("team", {
                            required: { value: true, message: "Please add a team name" },
                          })}
                        />
                        {errors.team && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.team.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="captainName">Team Captain Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="captainName"
                          {...register("captainName", {
                            required: { value: true, message: "Please add captain name" },
                          })}
                        />
                        {errors.captainName && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.captainName.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          aria-describedby="email"
                          {...register("email", {
                            required: { value: true, message: "Please enter a email" },
                            pattern: { value: emailRegex, message: "Please enter a valid email." },
                          })}
                        />
                        {errors.email && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.email.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="yearOfBirth">Birth Year(YYYY)</label>
                        <input
                          type="text"
                          className="form-control"
                          id="yearOfBirth"
                          {...register("yearOfBirth", {
                            required: { value: true, message: "Please enter a birth year." },
                            pattern: {
                              value: birthYearRegex,
                              message: "Please enter a correct birth year.",
                            },
                            max: { value: 2023, message: "Please enter a correct birth year." },
                          })}
                          aria-describedby="Birth Year"
                        />
                        {errors.yearOfBirth && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.yearOfBirth.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          {...register("address", {
                            required: { value: true, message: "Please enter a address." },
                          })}
                          aria-describedby="Address"
                        />
                        {errors.address && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.address.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          {...register("city", {
                            required: { value: true, message: "Please enter a city." },
                          })}
                          aria-describedby="City"
                        />
                        {errors.city && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.city.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          {...register("phone", {
                            required: { value: true, message: "Please enter phone number." },
                            pattern: {
                              value: looseNorthAmericanPhoneRegex,
                              message: "Please enter a valid phone number.",
                            },
                          })}
                          aria-describedby="phone"
                        />
                        {errors.phone && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.phone.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="cause_sel">The cause you're competing for</label>

                        <select
                          className="form-control"
                          {...register("cause", {
                            required: { value: true, message: "Please select an option." },
                          })}>
                          <option value="">Please Select</option>
                          <option value="Facility and/or equipment upgrades">
                            Facility and/or equipment upgrades
                          </option>
                          <option value="Non-profit organizations">Non-profit organizations</option>
                          <option value="Local ski related initiatives">
                            Local ski related initiatives
                          </option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.cause && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.cause.message}
                          </div>
                        )}

                        {(formwatch.cause === "Other" ||
                          formwatch.cause === "Autre (inscrire en toutes lettres)") && (
                          <input
                            type="text"
                            className="form-control mt-2"
                            {...register("cause_other", {
                              required: {
                                value: formwatch.cause === "Other" ? true : false,
                                message: "Please enter a cause.",
                              },
                            })}
                          />
                        )}
                        {errors.cause_other && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.cause_other.message}
                          </div>
                        )}
                      </div>
                      <br />
                      <h2>Question answers</h2>
                      <div className="form-group">
                        <label htmlFor="question1">
                        Mackenzie is searching for Canada's most invested ski community. What makes your Peak stand out from the rest?
                        </label>
                        <textarea
                          className="form-control"
                          id="question1"
                          {...register("question1", {
                            required: { value: true, message: "Please enter an answer." },
                          })}></textarea>
                        {errors.question1 && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.question1.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="question2">
                        How would your peak use $100,000 if awarded Mackenzie Top Peak 2024? Be specific on how your organization or team will benefit from these funds. (example: solar panels, sustainable upgrades, clubhouse renovations, or community programs..)
                        </label>
                        <textarea
                          className="form-control"
                          id="question2"
                          {...register("question2", {
                            required: { value: true, message: "Please enter an answer." },
                          })}
                        />
                        {errors.question2 && (
                          <div className="error-message">
                            <i className="fas fa-exclamation-circle" />
                            {errors.question2.message}
                          </div>
                        )}
                      </div>

                      <br />
                      <h2>Submitted photos/video</h2>
                      <div className="row">
                        <div className="submittedContent">
                          {renderMedias(formwatch.videoKey, formwatch.thumbnailKey)}
                        </div>
                      </div>
                      <div className="actionBtns">
                        {renderButtons(formwatch.teamStatus, isSubmitting)}
                      </div>
                    </form>

                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>

            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Modal"
              className={styles.Modal}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}>
                <span aria-hidden="true">
                  <img src="/assets/images/ico-close-black.png" alt="close" />
                </span>
              </button>
              <div className={styles.modal_content}>
                {/* <div>
                  <img src={preview!} style={{ width: "100%" }} className="modalPreview" alt="imagePreview" />
                </div> */}
                {!preview && !player && (
                  <div>
                    <h4>Decline Submission</h4>
                    <p>Provide a reason for declining the submission.</p>
                    <textarea
                      className="form-control"
                      id="reason"
                      {...register("declineReason")}
                      rows={3}></textarea>
                    <div className="actionBtns">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <button
                            type="button"
                            className="btn orange"
                            onClick={() => handleDecline()}>
                            Confirm
                          </button>
                        </div>

                        <div className="col-sm-12 col-md-6">
                          <button type="button" className="btn outline" onClick={closeModal}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {player && (
                  <div className={styles.iframe_container}>
                    <ReactPlayer
                      controls={true}
                      url={player}
                      config={{
                        file: {
                          attributes: {
                            onContextMenu: (e: any) => e.preventDefault(),
                          },
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubmissionDetails;
